.App {
  width: 80%;
  margin-left: 30px;
  margin-right: auto;
  font-family: ubuntu, sans-serif;
}

@media screen and (max-width: 1159px){
  .column {
    flex: 1
  }



  /*.double-column {
    flex: 2
  }
  */

}

.l635{
 margin-bottom: 11px;
 margin-left: 10px;
}

.big{
  font-size: 100px;
}

p {
  font-size: 14px;
}

a {
  font-size: 14px;
}

.home h1 {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 0;

}

.home {
  max-width: 350px;
}

.subheader {
  font-size: 14px;
  font-weight: 500;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.green{
margin-top:30px;
}

aside{
  position: fixed;
  width: 350px;
  background-color: white;
}

.map{

  margin-top:20px;
  height: 250px;
  width: 875px;
  margin-left:50px;
  /*margin-bottom:20px;*/
  
}

.pie{
  height: 250px;
  width: 340px;
  translate: -100px;
}

.pieBody{
 
  margin-top:-90px;
  margin-left: 25px;
}
.pieBody23{
 
  margin-top:-90px;
  
}

@media screen and (min-width: 651px){

  aside{
    position:sticky;
  }

  .map{
 
    margin-left:-50px;
  }


 
}

@media screen and (max-width: 651px){

  aside{
    position:sticky;
  }

  .map{
  width: 500px;
   margin-left:-70px;
   
  }
  .legend{
    margin-right:-300px;
    font-size: 10pt;
    translate: -120px;
 
  
  }

  .legend23{
    margin-right:-300px;
    font-size: 10pt;
    translate: -30px;
 
  
  }
  .pieBody{
    margin-top:3px;

  
  }

  .pieBody23{
    margin-top:3px;
    margin-left: 25px;

  
  }
  .brnodisplay {
    display: none;
}
 
}
.legend{
  margin-top: -65px;
  margin-bottom: 50px;
  margin-left: 200px;

}

.legend23{
  margin-top: -65px;
  margin-bottom: 0px;
  margin-left: 30px;
  margin-right: 20px;

}

.caption22{
  margin-bottom: 200px;
}

.caption{
  margin-bottom: 0px;

}


.tooltipA{
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: #EEEE transparent transparent transparent;
 
}